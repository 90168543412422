import PropTypes from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
// components
import Iconify from 'src/components/iconify';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import { isRefNo } from 'src/utils/custom-function';
import { useSearchParams } from 'src/routes/hooks';
import TourPlanActivities from './tour-plan-activities';

// ----------------------------------------------------------------------

export default function TourDayPlanDetails({ placeData, isView, refData }) {

  const { control, watch } = useFormContext();

  const searchParams = useSearchParams();

  const isEdit = JSON.parse(searchParams.get('isEdit')) || false;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tour_days',
  });

  const values = watch();

  const handleAdd = () => {
    append({
      day_no: '',
      from_place: { id: '', name: '' },
      to_place: { id: '', name: '' },
      hotel_name: '',
      hotel_type: '',
      activities: ['']
    });
  };

  const handleRemove = (index) => {
    remove(index);
  };

  return (
    <Box sx={{ p: 3 }}>
      <RHFSelect
        disabled={!!isView || (isEdit && isRefNo(values.referenceId))}
        name="referenceId" label="Reference No or Id *"
        InputLabelProps={{ shrink: true }}
        PaperPropsSx={{ textTransform: 'capitalize' }}
        sx={{ mb: 3 }}
      >
        {refData.map((option) => (
          <MenuItem key={option.id} value={option.ref_no}>
            {option.ref_no}
          </MenuItem>
        ))}
      </RHFSelect>

      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
        Tour Day:
      </Typography>

      <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
        {fields.map((item, index) => (
          <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
              <RHFTextField
                disabled={!!isView}
                size="small"
                name={`tour_days[${index}].day_no`}
                label="Day *"
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 96 } }}
              />
              <RHFSelect
                disabled={!!isView}
                size="small"
                name={`tour_days[${index}].from_place.id`}
                label="From *"
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                {placeData.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </RHFSelect>
              <RHFSelect
                disabled={!!isView}
                size="small"
                name={`tour_days[${index}].to_place.id`}
                label="To *"
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
              >
                {placeData.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </RHFSelect>
              <RHFTextField
                disabled={!!isView}
                size="small"
                name={`tour_days[${index}].hotel_name`}
                label="Hotel Name *"
                InputLabelProps={{ shrink: true }}
              />

              <RHFTextField
                disabled={!!isView}
                size="small"
                name={`tour_days[${index}].hotel_type`}
                label="Hotel Type *"
                InputLabelProps={{ shrink: true }}
              />
            </Stack>

            <TourPlanActivities nestIndex={index} isView={isView} {...{ control }} />

            {!isView && fields.length > 1 && (
              <Button
                size="small"
                color="error"
                startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                onClick={() => handleRemove(index)}
              >
                Remove Tour
              </Button>
            )}
          </Stack>
        ))}
      </Stack>
      {!isView && (
        <>
          <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

          <Stack
            spacing={3}
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-end', md: 'center' }}
          >
            <Button
              size="small"
              color="success"
              startIcon={<Iconify icon="mingcute:add-line" />}
              onClick={handleAdd}
              sx={{ flexShrink: 0 }}
            >
              Add Tour
            </Button>
          </Stack>
        </>
      )}

    </Box >
  );
}
TourDayPlanDetails.propTypes = {
  placeData: PropTypes.any,
  refData: PropTypes.any,
  isView: PropTypes.bool
};
import { configureStore, createSlice } from "@reduxjs/toolkit";
import tourDealsApi from "src/services/affordable/tour-deals/tour-deals-api";
import tourPackagesApi from "src/services/affordable/tour-packages/tour-packages-api";
import tourPlanApi from "src/services/affordable/tour-plan/tour-plan-api";
import { baseApi } from "src/services/base-api";
import driverAPI from "src/services/driver/driver-api";
import driverAvailabilityAPI from "src/services/availability/driver-availability";
import criteriaAPI from "src/services/home/criteria/criteria-api";
import driverReviewAPI from "src/services/home/driver_review/driver-review-api";
import leaderboardAPI from "src/services/home/leaderboard/leaderboard-api";
import inquiryAPI from "src/services/inquiry/inquiry-api";
import partnersAPI from "src/services/partners/partners-api";
import paymentAPI from "src/services/payment/payment-api";
import placesAPI from "src/services/places/places-api";
import profileAPI from "src/services/profile/profille-api";
import tourAPI from "src/services/tour/tour-api";
import staffAPI from "src/services/user/staff-api";
import consultantAPI from "src/services/user/consultant-api";
import driverBlockAPI from "src/services/driver/driver-block-api";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    criteriaData: null,
    reviewData: null,
    criteriaList: null,
    partnerData: null,
    partnerTypeData: null,
    placeData: null,
    approvedToursTabValue: 'availableTours',
    staffData: null,
    consultantData: null,
    driverGuideTabValue: 'view/edit',
    driverData: null,
    tourDriverData: null,
    tourDriversName: null,
    tourDealsTabValue: 'dealType',
    tourPackageData: null,
    vehicleData: null
  },
  reducers: {
    setCriteriaData: (state, action) => {
      if (action.payload) {
        state.criteriaData = {
          id: action.payload.id,
          label: action.payload.label,
          weight: action.payload.weight,
        };
      } else {
        state.criteriaData = null;
      }
    },
    setReviewData: (state, action) => {
      state.reviewData = action.payload;
    },
    setCriteriaList: (state, action) => {
      state.criteriaList = action.payload;
    },
    setPartnerData: (state, action) => {
      state.partnerData = action.payload;
    },
    setPartnerTypeData: (state, action) => {
      state.partnerTypeData = action.payload;
    },
    setPlaceData: (state, action) => {
      state.placeData = action.payload;
    },
    setApprovedToursTabValue: (state, action) => {
      state.approvedToursTabValue = action.payload;
    },
    setStaffData: (state, action) => {
      state.staffData = action.payload;
    },
    setConsultantData: (state, action) => {
      state.consultantData = action.payload;
    },
    setDriverGuideTabValue: (state, action) => {
      state.driverGuideTabValue = action.payload;
    },
    setDriverData: (state, action) => {
      state.driverData = action.payload;
    },
    setTourDriverData: (state, action) => {
      state.tourDriverData = action.payload;
    },
    setTourDriversName: (state, action) => {
      state.tourDriversName = action.payload;
    },
    setTourDealsTabValue: (state, action) => {
      state.tourDealsTabValue = action.payload;
    },
    setTourPackageData: (state, action) => {
      state.tourPackageData = action.payload;
    },
    setVehicleData: (state, action) => {
      state.vehicleData = action.payload;
    }
  },
});

const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    [baseApi.reducerPath]: baseApi.reducer,
    [leaderboardAPI.reducerPath]: leaderboardAPI.reducer,
    [driverReviewAPI.reducerPath]: driverReviewAPI.reducer,
    [criteriaAPI.reducerPath]: criteriaAPI.reducer,
    [profileAPI.reducerPath]: profileAPI.reducer,
    [partnersAPI.reducerPath]: partnersAPI.reducer,
    [placesAPI.reducerPath]: placesAPI.reducer,
    [inquiryAPI.reducerPath]: inquiryAPI.reducer,
    [tourAPI.reducerPath]: tourAPI.reducer,
    [driverAPI.reducerPath]: driverAPI.reducer,
    [driverAvailabilityAPI.reducerPath]: driverAvailabilityAPI.reducer,
    [paymentAPI.reducerPath]: paymentAPI.reducer,
    [staffAPI.reducerPath]: staffAPI.reducer,
    [consultantAPI.reducerPath]: consultantAPI.reducer,
    [tourPackagesApi.reducerPath]: tourPackagesApi.reducer,
    [tourDealsApi.reducerPath]: tourDealsApi.reducer,
    [tourPlanApi.reducerPath]: tourPlanApi.reducer,
    [driverBlockAPI.reducerPath]: driverBlockAPI.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(baseApi.middleware)
    .concat(leaderboardAPI.middleware)
    .concat(driverReviewAPI.middleware)
    .concat(criteriaAPI.middleware)
    .concat(profileAPI.middleware)
    .concat(partnersAPI.middleware)
    .concat(placesAPI.middleware)
    .concat(inquiryAPI.middleware)
    .concat(tourAPI.middleware)
    .concat(driverAPI.middleware)
    .concat(driverAvailabilityAPI.middleware)
    .concat(paymentAPI.middleware)
    .concat(staffAPI.middleware)
    .concat(consultantAPI.middleware)
    .concat(tourPackagesApi.middleware)
    .concat(tourDealsApi.middleware)
    .concat(tourPlanApi.middleware)
    .concat(driverBlockAPI.middleware)
});

export const homeActions = homeSlice.actions;

export default store;

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
// routes
import { Button } from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
import { paths } from 'src/routes/paths';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import { useRouter } from 'src/routes/hooks';
import FormProvider, {
  RHFTextField,
} from 'src/components/hook-form';
import { useCreateCriteriaMutation, useUpdateCriteriaMutation } from 'src/services/home/criteria/criteria-api';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';

// ----------------------------------------------------------------------

export default function ProductNewEditForm({ currentProduct }) {
  const router = useRouter();

  const confirm = useBoolean();

  const mdUp = useResponsive('up', 'md');

  const [isError, setIsError] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const NewProductSchema = Yup.object().shape({
    criteria: Yup.string().required('Criteria is required'),
    weight: Yup.number()
      .typeError('Weight must be a number')
      .required('Weight is required')
      .min(0, 'Weight must be at least 0')
      .max(1, 'Weight must be at most 1')
  });

  const defaultValues = useMemo(
    () => ({
      criteria: currentProduct?.label || '',
      weight: currentProduct?.weight || '',
    }),
    [currentProduct]
  );

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const [updateCriteria, { isSuccess: isSuccessUpdateCriteria, error: errorUpdateCriteria }] = useUpdateCriteriaMutation();
  const [createCriteria, { isSuccess: isSuccessCreateCriteria, error: errorCreateCriteria }] = useCreateCriteriaMutation();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (currentProduct) {
      reset(defaultValues);
    }
  }, [currentProduct, defaultValues, reset]);


  const onSubmit = handleSubmit(async (data) => {
    try {
      if (currentProduct) {
        const dataObject = {
          id: currentProduct.id,
          label: data.criteria,
          weight: parseFloat(data.weight),
        }
        await updateCriteria(dataObject);
      }
      else {
        const dataObject = {
          label: data.criteria,
          weight: parseFloat(data.weight),
        }
        await createCriteria(dataObject);
      }
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    if (isSuccessUpdateCriteria || isSuccessCreateCriteria) {
      reset();
      enqueueSnackbar(currentProduct ? 'Update success!' : 'Create success!');
      router.push(paths.dashboard.driverReview.criteria);
    }

  }, [currentProduct, enqueueSnackbar, isSuccessCreateCriteria, isSuccessUpdateCriteria, reset, router])


  useEffect(() => {
    if ((errorCreateCriteria || errorUpdateCriteria) && !isError) {
      confirm.onTrue();
      setIsError(true);
    }
  }, [confirm, errorCreateCriteria, errorUpdateCriteria, isError]);

  useEffect(() => {
    const currentURL = window.location.href;
    const parts = currentURL.split("/");
    const value = parts[parts.length - 1];
    if (value === 'edit') {
      if (!currentProduct) {
        router.push(paths.dashboard.driverReview.criteria);
      }
    }
  }, [currentProduct, router]);

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Criteria, Weight
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="criteria" label="Criteria *" />
            
            <RHFTextField
              name="weight"
              label="Weight *"
              placeholder="0"
              type="text"
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{ flexGrow: 1, pl: 3 }}
        />

        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
          {!currentProduct ? 'Create' : 'Save Changes'}
        </LoadingButton>
      </Grid>
    </>
  );

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    confirm.onFalse();
  }

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {/* {renderProperties}

        {renderPricing} */}

        {renderActions}
        <ConfirmDialog
          open={confirm.value}
          onClose={handleClose}
          title="Error"
          content={errorCreateCriteria ? errorCreateCriteria?.data?.message : errorUpdateCriteria?.data?.message}
          isError
          action={
            <Button variant="contained" color="error" onClick={() => {
              confirm.onFalse();
              router.reload()
            }}>
              Okay
            </Button>
          }
        />
      </Grid>
    </FormProvider>
  );
}

ProductNewEditForm.propTypes = {
  currentProduct: PropTypes.object,
};

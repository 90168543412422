import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../utils/baseQueryFn';

const driverBlockAPI = createApi({
    reducerPath: "driverBlockAPI ",
    baseQuery,
    endpoints: (builder) => ({
        getAllDriverBlockedDates: builder.mutation({
            query: ({ driverId }) => {
                const apiUrl = `tourdriver/block/driver/${driverId}`;

                return {
                    url: apiUrl,
                    method: 'GET',
                };
            },
            transformResponse: (response) => response,
            providesTags: ["driver_block"],
        }),
        getAllDriverBlockedExpires: builder.mutation({
            query: ({ page, size, startDate, endDate, key }) => {
                let apiUrl = `tourdriver/block/driver-expire?page=${page}&size=${size}&startDate=${startDate}`;

                if(endDate){
                    apiUrl += `&endDate=${endDate}`;
                }

                if (key) {
                    apiUrl += `&searchKey=${key}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["driver_block_expires"]
        }),
        addNewDriverBlockedDate: builder.mutation({
            query: (body) => ({
                url: `tourdriver/block`,
                method: "POST",
                body
            }),
            invalidatesTags: ["driver_block"],
        }),
        updateDriverBlockedDate: builder.mutation({
            query: ({ body }) => ({
                url: `tourdriver/block/change-dates`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["driver_block"],
        }),
        deleteDriverBlockedDate: builder.mutation({
            query: (blockId) => ({
                url: `tourdriver/block/${blockId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["driver_block"],
        })
    }),
});

export const { 
    useGetAllDriverBlockedDatesMutation,
    useGetAllDriverBlockedExpiresMutation,
    useAddNewDriverBlockedDateMutation,
    useUpdateDriverBlockedDateMutation,
    useDeleteDriverBlockedDateMutation
} = driverBlockAPI;

export default driverBlockAPI;
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';
// routes
import { useSnackbar } from 'src/components/snackbar';
import { paths } from 'src/routes/paths';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import { useRouter } from 'src/routes/hooks';
import FormProvider, {
    RHFTextField, RHFUpload,
} from 'src/components/hook-form';
import { useCreatePlaceMutation, useUpdatePlaceMutation } from 'src/services/places/places-api';
import { convertTimeToDate, getTimeFromString } from 'src/utils/format-time';
import { getFileExtension } from 'src/utils/custom-function';
import { useGetUploadURLMutation } from 'src/services/profile/profille-api';
import { useUploadToS3Mutation } from 'src/services/base-api';
import PlaceActivityDetails from './place-activity-details';

// ----------------------------------------------------------------------

export default function PlaceNewEditForm({ currentProduct, open, onClose, onSuccess }) {
    const router = useRouter();

    const mdUp = useResponsive('up', 'md');

    const [imageFile, setImageFile] = useState(null);

    const [formDataValue, setFormDataValue] = useState(null);

    const { enqueueSnackbar } = useSnackbar();

    const NewProductSchema = Yup.object().shape({
        name: Yup.string().required('Place name is required'),
        description: Yup.string().required('Description is required'),
        primaryImage: Yup.mixed().nullable().required('Primary place image cannot be empty'),
        activities: Yup.array().of(
            Yup.object().shape({
                activity: Yup.string().required('Activity name is required'),
                opening_time: Yup.string().required('Opening time is required'),
                closing_time: Yup.string().required('Closing time is required'),
                avg_spending_hours: Yup.string().required('Average spending time is required'),
            })
        ),
    });

    const defaultValues = useMemo(
        () => ({
            name: currentProduct?.name || '',
            description: currentProduct?.description || '',
            primaryImage: currentProduct?.primaryImage || null,
            activities: currentProduct?.placeActivities?.length
                ? activityList(currentProduct.placeActivities)
                : [
                    {
                        activity: '',
                        opening_time: convertTimeToDate('09:00:00'),
                        closing_time: convertTimeToDate('10:00:00'),
                        avg_spending_hours: 2,
                    },
                ],
        }),
        [currentProduct]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const [updatePlace, { isSuccess: isSuccessUpdatePlace, error: errorUpdatePlace }] = useUpdatePlaceMutation();
    const [createPlace, { isSuccess: isSuccessCreatePlace, error: errorCreatePlace }] = useCreatePlaceMutation();

    const [getUploadURL, { error: getUploadURLError, data: getUploadURLData, isSuccess: getUploadURLSuccess }] = useGetUploadURLMutation();
    const [uploadToS3, { error: uploadToS3Error, isSuccess: uploadToS3Success }] = useUploadToS3Mutation();

    const {
        setValue,
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        if (currentProduct) {
            reset(defaultValues);
        }
    }, [currentProduct, defaultValues, reset]);

    function transformActivityList(activities) {
        return activities.map(item => ({
            avg_spending_hours: parseFloat(item.avg_spending_hours),
            closing_time: getTimeFromString(item.closing_time),
            opening_time: getTimeFromString(item.opening_time),
            activity: item.activity
        }));
    }

    function activityList(activities) {
        return activities.map(item => ({
            avg_spending_hours: item.avg_spending_hours,
            closing_time: convertTimeToDate(item.closing_time),
            opening_time: convertTimeToDate(item.opening_time),
            activity: item.activity
        }));
    }

    const onSubmit = handleSubmit(async (data) => {
        try {
            if (currentProduct) {
                if (imageFile) {
                    setFormDataValue(data);
                    const fileExtension = getFileExtension(data.primaryImage);
                    await getUploadURL({ extension: fileExtension, type: "PLACE_IMG" });
                }
                else {
                    const body = {
                        'description': data.description,
                        'name': data.name,
                        'place_primary_image': data.primaryImage,
                        'place_activities': transformActivityList(data.activities),
                    }
                    await updatePlace({ id: currentProduct.uuid, body })
                }
            }
            else if (imageFile) {
                setFormDataValue(data);
                const fileExtension = getFileExtension(data.primaryImage);
                await getUploadURL({ extension: fileExtension, type: "PLACE_IMG" });
            }

        } catch (error) {
            console.error(error);
        }
    });

    useEffect(() => {
        if (getUploadURLSuccess && imageFile) {
            uploadToS3({ uploadUrl: getUploadURLData.uploadUrl, file: imageFile });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUploadURLSuccess])

    useEffect(() => {
        if (uploadToS3Success && formDataValue && getUploadURLData) {
            const body = {
                'description': formDataValue.description,
                'name': formDataValue.name,
                'place_primary_image': getUploadURLData.downloadUrl,
                'place_activities': transformActivityList(formDataValue.activities),
            }

            if (currentProduct) {
                updatePlace({ id: currentProduct.uuid, body })
            }
            else {
                createPlace(body);
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadToS3Success])

    useEffect(() => {
        if (isSuccessUpdatePlace || isSuccessCreatePlace) {
            reset();
            setFormDataValue(null);
            setImageFile(null);
            enqueueSnackbar(currentProduct ? 'Update success!' : 'Create success!');
            onClose();
            onSuccess();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessCreatePlace, isSuccessUpdatePlace])

    useEffect(() => {
        if (errorCreatePlace || errorUpdatePlace) {
            enqueueSnackbar(errorCreatePlace ? errorCreatePlace?.data?.message : errorUpdatePlace?.data?.message, { variant: 'error' });
        }
        if (getUploadURLError || uploadToS3Error) {
            enqueueSnackbar('Image upload Failed!', { variant: 'error' });
        }
    }, [enqueueSnackbar, errorCreatePlace, errorUpdatePlace, getUploadURLError, uploadToS3Error]);

    useEffect(() => {
        const currentURL = window.location.href;
        const parts = currentURL.split("/");
        const value = parts[parts.length - 1];
        if (value === 'edit') {
            if (!currentProduct) {
                router.push(paths.dashboard.place.root);
            }
        }
    }, [currentProduct, router]);

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('primaryImage', newFile, { shouldValidate: true });
                setImageFile(file);
            }
        },
        [setValue]
    );

    const handleRemoveFile = useCallback(() => {
        setValue('primaryImage', null);
        setImageFile(null);
    }, [setValue]);

    const renderbasicInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Basic Info
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Name, Description,Primary Place Image
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Basic Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFTextField name="name" label="Place Name *" />
                        <RHFTextField name="description" label="Description *" />
                        <Stack spacing={1.5}>
                            <Typography variant="subtitle2">Primary Place Image *</Typography>
                            <RHFUpload
                                name="primaryImage"
                                maxSize={3145728}
                                onDrop={handleDrop}
                                onDelete={handleRemoveFile}
                            />
                        </Stack>
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderActions = (
        <>
            {mdUp && <Grid md={4} />}
            <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box
                    sx={{ flexGrow: 1, pl: 3 }}
                />

                <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    {!currentProduct ? 'Create' : 'Save Changes'}
                </LoadingButton>
            </Grid>
        </>
    );

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={() => {
                reset();
                onClose();
            }}
            PaperProps={{
            sx: { maxWidth: 900 },
            }}
        >
            <DialogTitle>
                <Typography variant="h6" gutterBottom>
                    {
                        currentProduct ? "Edit" : "Create"
                    }
                </Typography>
            </DialogTitle>

            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Grid container spacing={3} sx={{px: 3}}>
                    {renderbasicInfo}
                    <PlaceActivityDetails/>
                    {/* {renderActions} */}
                </Grid>

                <DialogActions>
                    <Button variant="outlined" onClick={() => {
                        onClose();
                        reset();
                    }}
                    >
                        Close
                    </Button>

                    <LoadingButton type="submit" variant="contained" size="medium" loading={isSubmitting}>
                        {!currentProduct ? 'Create' : 'Update'}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}

PlaceNewEditForm.propTypes = {
    currentProduct: PropTypes.object,
    open: PropTypes.func, 
    onClose: PropTypes.func, 
    onSuccess: PropTypes.func
};

import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from '../../../utils/baseQueryFn';


const tourPlanApi = createApi({
    reducerPath: "tourPlanApi",
    baseQuery,
    endpoints: (builder) => ({
        getAllTourPlan: builder.mutation({
            query: ({ page, size, key }) => {
                let apiUrl = `tour/plan?page=${page}&size=${size}`;

                if (key) {
                    apiUrl += `&key=${key}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            providesTags: ["tour-plan"],
        }),
        addTourPlan: builder.mutation({
            query: (body) => ({
                url: `tour/plan`,
                method: "POST",
                body
            }),
            invalidatesTags: ["tour-plan"],
        }),
        updateTourPlan: builder.mutation({
            query: ({ id, body }) => ({
                url: `tour/plan/${id}`,
                method: "PUT",
                body
            }),
            invalidatesTags: ["tour-plan"],
        }),
        deleteTourPlan: builder.mutation({
            query: (id) => ({
                url: `tour/plan/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["tour-plan"],
        }),
        getAllInquiryRef: builder.query({
            query: () => {

                const queryParams = new URLSearchParams({
                    'queryAll': 'true',
                    'inquiryStatus': 'PENDING'
                });

                const apiUrl = `tour/inquiry?${queryParams.toString()}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response.content,
        }),
    }),
});

export const { useAddTourPlanMutation, useDeleteTourPlanMutation, useGetAllTourPlanMutation, useUpdateTourPlanMutation,useGetAllInquiryRefQuery } = tourPlanApi;
export default tourPlanApi;

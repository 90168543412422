import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';
// routes
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// components
import FormProvider, { RHFTextField, RHFSelect, RHFAutocomplete } from 'src/components/hook-form';
//
import { useResponsive } from 'src/hooks/use-responsive';
import RHFDatepicker from 'src/components/hook-form/rhf-date-picker';
import { useCreateDriverReviewMutation, useUpdateDriverReviewMutation } from 'src/services/home/driver_review/driver-review-api';
import { formatDate } from 'src/utils/format-time';

import Iconify from 'src/components/iconify';
// ----------------------------------------------------------------------

// const driversList = [
//   {id: 'SDG-SD-015', name: "Krishnan"},
//   {id: 'SDG-CG-002', name: "Abdulla"},
//   {id: 'SDG-TD-017', name: "Saman"},
// ];

export default function InvoiceNewEditForm({ currentProduct, isView }) {

  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const criteriaList = useSelector((state) => state.home.criteriaList);

  const today = new Date();

  const driversList = useSelector((state) => state.home.tourDriversName);

  const NewProductSchema = Yup.object().shape({
    driverId: Yup.string()
      // .matches(/^[a-zA-Z]+\s[a-zA-Z0-9-]+$/, 'Invalid driver ID format')
      .required('Driver ID is required'),
    tourRef: Yup.string().required('Tour Reference is required'),
    // driver: Yup.string().required('Driver required'),
    startDate: Yup.date()
      .required('Start Date is required')
      .typeError("Please select a valid date")
      .nullable()
      .max(today, 'Start Date cannot be in the future'),
    endDate: Yup.date()
      .required('End Date is required')
      .typeError("Please select a valid date")
      .max(today, 'End Date cannot be in the future')
      .min(
        Yup.ref('startDate'),
        "End date can't be before start date"
      )
      .nullable(),
    ...(currentProduct
      ? currentProduct?.criteriaPoints?.reduce((validationSchema, criteria) => {
        validationSchema[criteria.criteria.toLowerCase()] = Yup.number()
          .min(1, `${criteria.criteria} must be between 1 and 10`)
          .max(10, `${criteria.criteria} must be between 1 and 10`)
          .nullable();
        return validationSchema;
      }, {})
      : criteriaList?.reduce((validationSchema, criteria) => {
        validationSchema[criteria.label.toLowerCase()] = Yup.number()
          .required('Point(s) is required')
          .typeError('Invalid number')
          .min(1, `${criteria.label} must be between 1 and 10`)
          .max(10, `${criteria.label} must be between 1 and 10`)
          .nullable();
        return validationSchema;
      }, {})),
  });

  const defaultValues = useMemo(() => {
    const initialValues = {
      driverId: currentProduct ? currentProduct?.driverName.concat("-").concat(currentProduct?.tourDriverId) : '',
      driver: currentProduct?.tourDriverId || null,
      tourRef: currentProduct?.tourRef || '',
      startDate: new Date(currentProduct?.tourStartDate) || null,
      endDate: new Date(currentProduct?.tourEndDate) || null,
    };

    if (currentProduct) {
      currentProduct?.criteriaPoints?.forEach((criteria) => {
        initialValues[criteria.criteria.toLowerCase()] = criteria.points;
      });
    } else if (criteriaList) {
      criteriaList?.forEach((criteria) => {
        initialValues[criteria.label.toLowerCase()] = 0;
      });
    }

    return initialValues;
  }, [criteriaList, currentProduct]);

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const [updateReview, { isSuccess: isSuccessUpdateCriteria, isError: isErrorUpdateReview, error: updateErrorMsg}] = useUpdateDriverReviewMutation();
  const [createReview, { isSuccess: isSuccessCreateCriteria, isError: isErrorCreateReview, error: createErrorMsg}] = useCreateDriverReviewMutation();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    watch
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentProduct) {
      reset(defaultValues);
    }
  }, [currentProduct, defaultValues, reset]);

  useEffect(() => {
    const currentURL = window.location.href;
    const parts = currentURL.split("/");
    const value = parts[parts.length - 1];
    if (value === 'edit' || value === 'view') {
      if (!currentProduct) {
        router.push(paths.dashboard.driverReview.review);
      }
    }
  }, [currentProduct, router]);

  const getDriverName = (value) => {
    const firstHyphenIndex = value.indexOf("-");
    return value.substring(0, firstHyphenIndex);
  }

  const getDriverID = (value) => {
    const firstHyphenIndex = value.indexOf("-");
    return value.substring(firstHyphenIndex + 1, value.length);
  }

  const onSubmit = handleSubmit(async (data) => {
    // console.log(data);
    try {
      if (currentProduct) {
        const criteriaPoints = currentProduct?.criteriaPoints;
        const updatedCriteriaPoints = criteriaPoints.map(criteria => {
          const propertyName = criteria.criteria.toLowerCase();
          if (Object.prototype.hasOwnProperty.call(data, propertyName)) {
            return {
              ...criteria,
              points: data[propertyName],
            };
          }
          return criteria;
        });
        const dataObject = {
          criteriaPoints: updatedCriteriaPoints,
          reviewId: currentProduct.id,
          startDate: formatDate(data.startDate),
          endDate: formatDate(data.endDate)
        }

        console.log(dataObject)

        await updateReview(dataObject);
      }
      else {
        const resultList = Object.keys(data)
          .map(propertyName => ({
            propertyName,
            criteriaLabel: propertyName.toLowerCase()
          }))
          .filter(({ criteriaLabel }) =>
            criteriaList.some(criteria => criteria.label.toLowerCase() === criteriaLabel)
          )
          .map(({ propertyName, criteriaLabel }) => ({
            criteriaId: criteriaList.find(criteria => criteria.label.toLowerCase() === criteriaLabel).id,
            points: data[propertyName]
          }));

          const dataObject = {
            criteriaPoints: resultList,
            driverUUID: getDriverID(data.driverId),
            driverName: getDriverName(data.driverId),
            tourRef: data.tourRef,
            startDate: formatDate(data.startDate),
            endDate: formatDate(data.endDate)
          }

          console.log(dataObject)

          await createReview(dataObject);
      }
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    if (isSuccessUpdateCriteria || isSuccessCreateCriteria) {
      reset();
      enqueueSnackbar(currentProduct ? 'Update success!' : 'Create success!');
      router.push(paths.dashboard.driverReview.review);
    }
    else if(isErrorUpdateReview || isErrorCreateReview){
      console.log(createErrorMsg);
      enqueueSnackbar(isErrorCreateReview ? createErrorMsg?.data?.message : updateErrorMsg?.data?.message, {variant: 'error'});
    }
  }, [
    currentProduct, 
    enqueueSnackbar, 
    isSuccessCreateCriteria, 
    isSuccessUpdateCriteria, 
    isErrorUpdateReview, 
    isErrorCreateReview, 
    createErrorMsg, 
    updateErrorMsg,
    reset, 
    router
  ])

  const renderIDDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Driver
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Choose driver
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Driver" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            {
              isView || currentProduct
              ?
              <RHFTextField 
                name="driverId" 
                label="Driver *" 
                disabled
              />
              :
              <>
                {
                  driversList?.length > 0
                  ?
                  <RHFAutocomplete
                    name="driverId"
                    label="Driver *"
                    disabled={currentProduct}
                    options={driversList.map((driver) => driver.driver_name.concat("-").concat(driver.driver_id))}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderOption={(props, option) => {
                      const { driver_name, driver_id } = driversList.filter((driver) => {
                        console.log(option);
                        return driver.driver_name === option
                      });

                      return (
                        <li {...props} key={driver_id}>
                          {option}
                        </li>
                      );
                    }}
                  />
                  :
                  <></>
                }
              </>
            }
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Tour
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Tour References are here
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Tour" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="tourRef" label="Tour Reference *" disabled={!!currentProduct} />

            <RHFDatepicker name="startDate" label="Start Date *" disabled={!!isView} maxDate={new Date()}/>

            <RHFDatepicker name="endDate" label="End Date *" disabled={!!isView} minDate={values.startDate} maxDate={new Date()}/>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderCriteria = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Points
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Points related inputs
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Points" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            {currentProduct
              ? currentProduct.criteriaPoints.map((criteria, index) => (
                <RHFTextField
                  disabled={!!isView}
                  key={criteria.id}
                  name={criteria.criteria.toLowerCase()}
                  label={`${criteria.criteria} *`}
                  placeholder="Enter Points 1 to 10"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                />
              ))
              : criteriaList?.map((criteria, index) => (
                <RHFTextField
                  key={criteria.id}
                  name={criteria.label.toLowerCase()}
                  label={`${criteria.label} *`}
                  placeholder="Enter Points 1 to 10"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                />
              ))
            }
          </Stack>

        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{ flexGrow: 1, pl: 3 }}
        />

        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
          {!currentProduct ? 'Create' : 'Save Changes'}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderIDDetails}

        {renderDetails}

        {renderCriteria}

        {isView ? null : renderActions}
      </Grid>
    </FormProvider>
  );
}

InvoiceNewEditForm.propTypes = {
  currentProduct: PropTypes.object,
  isView: PropTypes.bool,
};

import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo, useState, useRef } from 'react';
import { EmailAuthProvider, reauthenticateWithCredential, signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { auth } from 'src/firebase/firebase';
import { useGetUserRoleMutation } from 'src/services/profile/profille-api';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'FORGETPASSWORD') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';
const STORAGE_USER_KEY = 'user';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Live
  // const adminAccess = useRef([
  //   { sub_access: [true, true, true] }, // Driver ranking
  //   { sub_access: [true, true] }, // Driver availability
  //   { sub_access: [true] }, // Places
  //   { sub_access: [false, false, false] }, // Affordable
  //   { sub_access: [false, false, false] }, // Inquiries
  //   { sub_access: [false, false, false, false, false] }, // Tours
  //   { sub_access: [false] }, // Payments
  //   { sub_access: [true, true, false, true, false] }, // Users
  //   { sub_access: [true, true] }, // Partners
  //   { sub_access: [true] } // Profile
  // ]);

  // Staging
  const adminAccess = useRef([
    { sub_access: [true, true, true] }, // Driver ranking
    { sub_access: [true, true] }, // Driver availability
    { sub_access: [true] }, // Places
    { sub_access: [true, true, true] }, // Affordable
    { sub_access: [true, true, true] }, // Inquiries
    { sub_access: [true, true, true, true, true] }, // Tours
    { sub_access: [true] }, // Payments
    { sub_access: [true, true, true, true, true] }, // Users
    { sub_access: [true, true] }, // Partners
    { sub_access: [true] } // Profile
  ]);

  const consultantAccess = useRef([
    { sub_access: [true, false, false] }, // Driver ranking
    { sub_access: [false, false] }, // Driver availability
    { sub_access: [false] }, // Places
    { sub_access: [false, false, false] }, // Affordable
    { sub_access: [false, false, false] }, // Inquiries
    { sub_access: [false, false, false, false, false] }, // Tours
    { sub_access: [false] }, // Payments
    { sub_access: [false, false, false, false, false] }, // Users
    { sub_access: [false, false] }, // Partners
    { sub_access: [true] } // Profile
  ]);

  const [isAccessVerified, setIsAccessVerified] = useState(false);
  const [availableAccess, setAvailableAccess] = useState(adminAccess.current);

  const [getUserRole, { error: errorGetUserRole, isError: isGetUserRoleError, isSuccess: isGetUserRoleSuccess, data: userRole }] = useGetUserRoleMutation();

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);
      const user = JSON.parse(sessionStorage.getItem(STORAGE_USER_KEY));

      if (accessToken && isValidToken(accessToken) && user) {
        await getUserRole();

        setSession(accessToken);

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, [getUserRole]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const response = await signInWithEmailAndPassword(auth, email, password);

    setIsAccessVerified(false);

    response.user.getIdToken().then(async (value) => {
      setSession(value);
      await getUserRole();

      const { user } = response;

      sessionStorage.setItem(STORAGE_USER_KEY, JSON.stringify(user));

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    });
  }, [getUserRole]);

  useEffect(() => {
    if(isGetUserRoleSuccess && userRole){
      console.log("User role...");

      if(!isAccessVerified){
        console.log(userRole);
        setIsAccessVerified(true);
    
        if(userRole === 'TOUR_CONSULTANT'){
          setAvailableAccess(consultantAccess.current);
        }
        else{
          setAvailableAccess(adminAccess.current);
        }
      }
    }
    else if(isGetUserRoleError && errorGetUserRole){
      console.log("User role error...");
      console.log(errorGetUserRole);
    }
  }, [isGetUserRoleSuccess, userRole, errorGetUserRole, isGetUserRoleError, isAccessVerified, adminAccess, consultantAccess]);

  // FORGET PASSWORD
  const forgetPassword = useCallback(async (email) => {


    // const response = await axios.post(endpoints.auth.register, data);

    // const { accessToken, user } = response.data;

    // sessionStorage.setItem(STORAGE_KEY, accessToken);

    // dispatch({
    //   type: 'FORGETPASSWORD',
    //   payload: {
    //     user,
    //   },
    // });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);

    sessionStorage.removeItem(STORAGE_USER_KEY);

    setIsAccessVerified(false);

    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // CHANGEPASSWORD
  const changePassword = useCallback(async (currentPassword, newPassword) => {

    if (auth != null) {
      const { email } = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        email, currentPassword);
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, newPassword);
    }
  }, []);

  // ROLE BASED GUARD
  // To check main menu access only pass mainMenuOrder
  const isUserRoleHasAccess = useCallback((mainMenuOrder, subMenuOrder = -1) => {
    if(mainMenuOrder > availableAccess.length - 1 || subMenuOrder > availableAccess[mainMenuOrder].sub_access.length - 1){
      return true;
    }

    if(subMenuOrder === -1){
      let hasAccess = false;

      for(let i = 0; i < availableAccess[mainMenuOrder].sub_access.length; i += 1){
        if(availableAccess[mainMenuOrder].sub_access[i]){
          hasAccess = true;
          break;
        }
      }

      return hasAccess;
    }

    return availableAccess[mainMenuOrder].sub_access[subMenuOrder];
  }, [availableAccess]);
  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      availableAccess,
      //
      login,
      forgetPassword,
      logout,
      changePassword,
      isUserRoleHasAccess
    }),
    [login, logout, forgetPassword, changePassword, isUserRoleHasAccess, state.user, status, availableAccess]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};

import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';
// routes
import { useSnackbar } from 'src/components/snackbar';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import FormProvider, {
    RHFCheckbox,
    RHFSelect,
    RHFTextField,
} from 'src/components/hook-form';
import RHFDatepicker from 'src/components/hook-form/rhf-date-picker';
import { district } from 'src/assets/data';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { formatDate } from 'src/utils/format-time';
import { useGetAllVehicleModelsQuery, useRegisterDriverMutation, useUpdateDriverByIdMutation } from 'src/services/driver/driver-api';
import { LoadingScreen } from 'src/components/loading-screen';
import { getModifiedMobileNumber } from 'src/utils/custom-function';
import ErrorView from 'src/sections/error/error-view';
import BankDetails from '../bank-details';
import VehicleDetails from './vehicle_details';

// ----------------------------------------------------------------------
const driverTypeList = [
    "STANDARD_DRIVER",
    "LICENSED_TOURIST_DRIVER",
    "PRIVATE_CHAUFFEUR_GUIDE",
    "LICENSED_NATIONAL_TOUR_GUIDE",
];

const relationShipList = ['Father', 'Mother', 'Brother', 'Sister', 'Guardian', 'Grand Father', 'Grand Mother', 'Other'];

export default function DriverForm({ driver, isEdit, isView, status }) {

    const mdUp = useResponsive('up', 'md');

    const router = useRouter();

    const { enqueueSnackbar } = useSnackbar();

    const NewProductSchema = Yup.object().shape({

        // BASIC INFO
        driverType: Yup.string().required('Driver type cannot be empty or invalid'),
        // driverId: Yup.string().required('Driver id cannot be empty or invalid'),
        email: Yup.string().required('Email is required').email('Email must be a valid email address'),
        mobileNumber: Yup.string().required('Mobile number cannot be empty or Invalid mobile number'),
        nic: Yup.string().required('NIC cannot be empty or Invalid NIC'),

        // PROFILE INFO
        firstName: Yup.string().required('First Name cannot be empty or invalid'),
        lastName: Yup.string().required('Last Name cannot be empty or invalid'),
        birthDate: Yup.date()
            .required('Date of birth cannot be empty')
            .typeError("Please select a valid date")
            .nullable(),
        address: Yup.string().required('Address cannot be empty'),
        whatsupNumber: Yup.string().required('Whatsup number cannot be empty or Invalid Whatsup number'),
        district: Yup.string().required('District cannot be empty'),

        // EMERGENCY CONTACT
        emeName: Yup.string().required('Emergency contact person name cannot be empty or invalid'),
        emeRelationship: Yup.string().required('Emergency contact person relationship cannot be empty or invalid'),
        emeNumber: Yup.string().required('Emergency contact person number cannot be empty'),

        // VEHICLE INFO
        // insuranceImage: Yup.mixed().nullable().required('Vehicle insurance certificate cannot be empty'),
        // licenceFrontImage: Yup.mixed().nullable().required('Vehicle front image cannot be empty'),
        // licenceBackImage: Yup.mixed().nullable().required('Vehicle rear image cannot be empty'),

        // BANK DETAILS
        bankDetails: Yup.array().of(
            Yup.object().shape({
                account_holder_name: Yup.string().required('Name mentioned in bank account cannot be empty or invalid'),
                bank_name: Yup.string().required('Bank name cannot be empty'),
                account_number: Yup.number()
                    .typeError('Account number must be a number')
                    .required('Account number cannot be empty or invalid'),
                branch: Yup.string().required('Branch name cannot be empty or invalid'),
            })
        ),
    });

    const { isLoading: isLoadingGetAllVehicle, isSuccess: isSuccessGetAllVehicle, data: getAllVehicleData, error: errorGetAllVehicle } =
        useGetAllVehicleModelsQuery();

    const [updateDriver, { isSuccess: isSuccessUpdateDriver, error: errorUpdateDriver }] = useUpdateDriverByIdMutation();

    const [createDriver, { isSuccess: isSuccessCreateDriver, error: errorCreateDriver }] = useRegisterDriverMutation();

    function transformArray(inputArray) {
        return inputArray.map(item => ({
            back_img: item.back_img,
            front_img: item.front_img,
            no_of_seats: parseInt(item.no_of_seats, 10),
            owner: item.owner,
            rear_img: item.rear_img,
            brand: item.vehicle_model.brand.name,
            model: item.vehicle_model.model,
            year: item.vehicle_model.year,
            vehicle_number: item.vehicle_number,
            vehicle_type: item.vehicle_type
        }));
    }

    const defaultValues = useMemo(
        () => ({

            // BASIC INFO
            driverType: driver?.driver_type || driverTypeList[0],
            // driverId: driver?.user_name || '',
            email: driver?.email || '',
            mobileNumber: driver?.user_profile?.phone_no || '',
            nic: driver?.user_profile?.nic || '',
            checkbox: driver?.payment_collector || false,

            // PROFILE INFO
            firstName: driver?.user_profile?.first_name || '',
            lastName: driver?.user_profile?.last_name || '',
            displayName: driver?.user_profile?.display_name || '',
            birthDate: new Date(driver?.user_profile?.dob) || null,
            address: driver?.user_profile?.address.first_address || '',
            whatsupNumber: driver?.user_profile?.whatsapp_no || '',
            district: driver?.user_profile?.address.district || null,

            // EMERGENCY CONTACT
            emeName: driver?.emergency_contacts?.name || '',
            emeRelationship: driver?.emergency_contacts?.relation_ship || '',
            emeNumber: driver?.emergency_contacts?.number || '',


            // VEHICLE INFO
            insuranceImage: driver?.insurance_certificate || null,
            licenceFrontImage: driver?.driving_license?.front_img || null,
            licenceBackImage: driver?.driving_license?.back_img || null,

            vehicles: driver?.vehicles?.length
                ? driver?.vehicles
                : [],

            // BANK DETAILS
            bankDetails: driver?.bank_detail?.length
                ? driver?.bank_detail
                : [],
        }),
        [driver]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting, },
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        try {
            if (driver) {
                const body = {
                    "user_name": '',// data.driverId,
                    "first_name": data.firstName,
                    "last_name": data.lastName,
                    "dob": formatDate(data.birthDate),
                    "display_name": data.displayName,
                    "whatsapp_no": getModifiedMobileNumber(data.whatsupNumber),
                    "profile_img": null,
                    "address": data.address,
                    "district": data.district,
                    "emergency_contact_name": data.emeName,
                    "emergency_contact_relationship": data.emeRelationship,
                    "emergency_contact_number": getModifiedMobileNumber(data.emeNumber),
                    "payment_collector": data.checkbox,
                    "bank_detail": data.bankDetails,
                    "driving_license": { 'front_img': data.licenceFrontImage, 'back_img': data.licenceBackImage },
                    "driver_type": data.driverType,
                    "insurance_certificate": data.insuranceImage,
                    "vehicles": transformArray(data.vehicles)
                };
                await updateDriver({ id: driver.id, body });
            } else {
                const body = {
                    "user_name": '',// data.driverId,
                    "first_name": data.firstName,
                    "last_name": data.lastName,
                    "dob": formatDate(data.birthDate),
                    "phone_no": getModifiedMobileNumber(data.mobileNumber),
                    "display_name": data.displayName,
                    "nic": data.nic,
                    "whatsapp_no": getModifiedMobileNumber(data.whatsupNumber),
                    "profile_img": null,
                    "email": data.email,
                    "address": data.address,
                    "district": data.district,
                    "emergency_contact_name": data.emeName,
                    "emergency_contact_relationship": data.emeRelationship,
                    "emergency_contact_number": getModifiedMobileNumber(data.emeNumber),
                    "payment_collector": data.checkbox,
                    "bank_detail": data.bankDetails,
                    "driving_license": { 'front_img': data.licenceFrontImage, 'back_img': data.licenceBackImage },
                    "referral_code": null,
                    "driver_type": data.driverType,
                    "insurance_certificate": data.insuranceImage,
                    "vehicles": transformArray(data.vehicles)
                }
                await createDriver(body);
            }
        } catch (error) {
            console.error(error);
        }
    });

    useEffect(() => {
        const currentURL = window.location.href;
        const parts = currentURL.split("/");
        const value = parts[parts.length - 1];
        if (value === 'edit') {
            if (!driver) {
                if(status === "APPROVED"){
                    router.push(paths.dashboard.user.detailedApprovedDriverGuide);
                }
                else{
                    router.push(paths.dashboard.user.detailedPendingDriverGuide);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router, driver]);

    useEffect(() => {
        if (driver) {
            reset(defaultValues);
        }
    }, [defaultValues, reset, driver]);

    useEffect(() => {
        if (isSuccessUpdateDriver || isSuccessCreateDriver) {
            enqueueSnackbar('Successfully updated!');

            if(status === "APPROVED"){
                router.push(paths.dashboard.user.detailedApprovedDriverGuide);
            }
            else{
                router.push(paths.dashboard.user.detailedPendingDriverGuide);
            }
        }

        if (errorUpdateDriver || errorCreateDriver) {
            enqueueSnackbar(errorCreateDriver ? errorCreateDriver?.data?.message : errorUpdateDriver?.data?.message, { variant: 'error' });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enqueueSnackbar, errorCreateDriver, errorUpdateDriver, isSuccessCreateDriver, isSuccessUpdateDriver, router])

    const renderBasicInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Basic Info
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Basic Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFSelect
                            fullWidth
                            name="driverType"
                            label="Driver Type"
                            InputLabelProps={{ shrink: true }}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                            disabled={!!isView}
                        >
                            {driverTypeList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        {/* <RHFTextField  name="driverId" label="Driver Id *" disabled={!!isView} /> */}

                        {
                            isEdit
                            ?
                            <></>
                            :
                            <>
                                <RHFTextField disabled={!!isEdit || !!isView} name="email" label="Email *" />
                                <RHFTextField disabled={!!isEdit || !!isView} name="mobileNumber" label="Mobile Number *" />
                                <RHFTextField disabled={!!isEdit || !!isView} name="nic" label="NIC *" />
                            </>
                        }
                        
                        <RHFCheckbox name="checkbox" label="is payment collector" disabled={!!isView} />
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderProfileInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Profile Info
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Profile Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFTextField disabled={!!isEdit || !!isView} name="firstName" label="First Name *" />
                        <RHFTextField disabled={!!isEdit || !!isView} name="lastName" label="Last Name *" />
                        <RHFTextField disabled={!!isEdit || !!isView} name="displayName" label="Display Name" />
                        <RHFDatepicker disabled={!!isEdit || !!isView} name="birthDate" label="Date of birth *" />
                        <RHFTextField disabled={!!isEdit || !!isView} name="address" label="Address *" />
                        <RHFTextField disabled={!!isEdit || !!isView} name="whatsupNumber" label="Whatsapp  Number *" />
                        <RHFSelect
                            disabled={!!isEdit || !!isView}
                            fullWidth
                            name="district"
                            label="District *"
                            InputLabelProps={{ shrink: true }}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                        >
                            {district.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderEmergnecyContactInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Emergnecy Contact
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Emergnecy Contact" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFTextField  name="emeName" label="Emergency contact person name *" disabled={!!isView} />

                        <RHFSelect
                            fullWidth
                            name="emeRelationship"
                            label="Emergency contact person relationship *"
                            InputLabelProps={{ shrink: true }}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                            disabled={!!isView}
                        >
                            {relationShipList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>

                        <RHFTextField  name="emeNumber" label="Emergency contact person number *" disabled={!!isView} />
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderBankDetails = (
        <BankDetails isView={isView}/>
    );

    const renderActions = (
        <>
            {mdUp && <Grid md={4} />}
            <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box
                    sx={{ flexGrow: 1, pl: 3 }}
                />

                <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    {!driver ? 'Create' : 'Save Changes'}
                </LoadingButton>
            </Grid>
        </>
    );

    let vehicleContent;
    if (isLoadingGetAllVehicle) {
        vehicleContent = <LoadingScreen />
    }
    else if (errorGetAllVehicle) {
        vehicleContent = <>
            <ErrorView error={errorGetAllVehicle?.data} showTryAgain={false}/>
        </>
    }
    else if (isSuccessGetAllVehicle) {
        if (getAllVehicleData.length > 0) {
            vehicleContent = (
                <>
                    {renderBasicInfo}

                    {
                        isEdit
                        ?
                        <></>
                        :
                        <>
                            {renderProfileInfo}
                        </>
                    }
                    
                    {renderEmergnecyContactInfo}
                    <VehicleDetails vehicleData={getAllVehicleData} isView={!!isView}/>
                    {renderBankDetails}
                    {!isView ? renderActions : <></>}
                </>
            );
        }
        else {
            vehicleContent = <Typography variant="h6" sx={{ mb: 0.5 }}>
                Please Add Vehicle
            </Typography>
        }

    }

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid container spacing={3}>
                {vehicleContent}
            </Grid>
        </FormProvider>
    );
}

DriverForm.propTypes = {
    driver: PropTypes.object,
    isEdit: PropTypes.bool,
    isView: PropTypes.bool,
    status: PropTypes.string
};
